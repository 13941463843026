import { PageTopBar } from 'next-app/app/(authenticated)/_components/PageTopBar'
import {
  LEVEL_WORKSPACE_DD_MENU_OPTIONS_HIGH_PERMISSIONS,
  LEVEL_WORKSPACE_DD_MENU_OPTIONS_LOW_PERMISSIONS,
  NAVIGATION_DD_MENU_CLICK,
} from 'next-app/src/utils/cmsNavigationUtils'
import { useParams } from 'next/navigation'
import { FC, useMemo } from 'react'

import { WorkspaceGroup } from '@models/types'

import { styled } from '../stitches.config'

export type AuthenticatedCenteredTemplateProps = {
  children?: React.ReactNode
  logout: () => Promise<void>
  workspacePermissions: WorkspaceGroup[]
  textContainerSize?: 'medium' | 'large'
}

export const AuthenticatedCenteredTemplate: FC<AuthenticatedCenteredTemplateProps> = ({
  children,
  logout,
  workspacePermissions,
  textContainerSize = 'medium',
}) => {
  const params = useParams()
  const brand = params.brand as string
  const siteId = params.siteId as string
  const workspace = params.workspace as string

  const dropdownMenuOptions = useMemo(() => {
    return workspacePermissions.includes('Admin') || workspacePermissions.includes('Accounting')
      ? LEVEL_WORKSPACE_DD_MENU_OPTIONS_HIGH_PERMISSIONS
      : LEVEL_WORKSPACE_DD_MENU_OPTIONS_LOW_PERMISSIONS
  }, [workspacePermissions])

  return (
    <Container>
      <PageTopBar
        viewState="default"
        dropdownMenuOptions={dropdownMenuOptions}
        dropdownMenuItemClick={NAVIGATION_DD_MENU_CLICK({
          workspace,
          brand,
          siteId,
          logout,
        })}
        css={{
          zIndex: 2000,
          boxShadow: '$bottom',
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
        }}
      />
      <TextContainer textContainerSize={textContainerSize}>{children}</TextContainer>
    </Container>
  )
}

const Container = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100dvh',
})
const TextContainer = styled('div', {
  textAlign: 'center',
  color: '$gs12',
  paddingTop: '80px',
  pb: '$10',
  variants: {
    textContainerSize: {
      medium: {
        maxWidth: '650px',
      },
      large: {
        maxWidth: '750px',
      },
    },
  },
})
