import { useParams } from 'next/navigation'
import { useSignout } from 'pattern-library/src/hooks/useSignout'
import {
  AuthenticatedCenteredTemplate,
  AuthenticatedCenteredTemplateProps,
} from 'pattern-library/src/templates/AuthenticatedCenteredTemplate'
import { FC } from 'react'
import { useListUserWorkspacesQuery } from 'src/graphql/operations'
import { useGetAllAppContext } from 'src/hooks/useGetAllAppContext'

type AuthenticatedCenteredTemplateWithDataProps = Pick<
  AuthenticatedCenteredTemplateProps,
  'textContainerSize' | 'children'
>

export const AuthenticatedCenteredTemplateWithData: FC<AuthenticatedCenteredTemplateWithDataProps> = props => {
  const { user } = useGetAllAppContext()
  const params = useParams()
  const workspace = params.workspace as string
  const username = user?.email || ''

  const { signOut } = useSignout()

  const { data, error, loading } = useListUserWorkspacesQuery({
    fetchPolicy: 'cache-and-network',
    // nextFetchPolicy: 'cache-first',
    variables: {
      username,
    },
    skip: !username,
    onError: error => {
      console.error('useListUserWorkspacesQuery error:', error)
    },
  })

  const currentWorkspace = data?.listUserWorkspaces?.find(wksp => wksp.subdomain === workspace)

  return (
    <AuthenticatedCenteredTemplate
      {...props}
      workspacePermissions={currentWorkspace?.getWorkspaceUser?.workspaceGroups || []}
      logout={signOut}
    />
  )
}
