import { Button } from '@pattern-library/src/basicUI/Button'
import { Flex } from '@pattern-library/src/basicUI/Flex'
import { Text } from '@pattern-library/src/basicUI/Text'
import { CSS, styled } from '@pattern-library/src/stitches.config'
import { DropdownMenuProps } from 'pattern-library/src/basicUI/Dropdowns/DropdownMenu'
import { FC } from 'react'

import { AppLogo } from '../../_components/AppLogo'
import { DropdownWithUserProfile } from './DropdownWithUserProfile'

type PageTopBarProps = {
  viewState: 'saving' | 'editing' | 'default'
  dropdownMenuOptions: DropdownMenuProps['options']
  dropdownMenuItemClick: DropdownMenuProps['onSelectMenuItem']
  onSaveClick?: any
  onDiscardClick?: any
  css?: CSS
}

export const PageTopBar: FC<PageTopBarProps> = ({
  viewState,
  dropdownMenuOptions,
  onSaveClick,
  onDiscardClick,
  dropdownMenuItemClick,
  css,
}) => {
  return (
    <TopBarContainer unsavedEditsView={viewState !== 'default'} css={css}>
      {viewState === 'default' ? (
        <>
          <AppLogo requestedHeight={36} />
          <div>
            <ItemContainer as={'div'}>
              <DropdownWithUserProfile
                dropdownMenuOptions={dropdownMenuOptions}
                dropdownMenuItemClick={dropdownMenuItemClick}
              />
            </ItemContainer>
          </div>
        </>
      ) : (
        <Flex justify="between" align="center" css={{ position: 'relative' }}>
          <AppLogo
            requestedHeight={36}
            reverseThemePalette={true}
            css={{
              display: 'none',
              '@bp2': {
                display: 'block',
              },
            }}
          />
          <Text>Unsaved changes</Text>
          <Flex css={{ width: 'auto' }}>
            <Button
              label="Discard"
              // variant="ghost"
              variant="tertiary"
              onClick={onDiscardClick}
              css={{ marginRight: '$5' }}
            />
            <Button
              label="Save"
              variant="secondary"
              onClick={onSaveClick}
              buttonState={viewState === 'saving' ? 'waiting' : 'default'}
            />
          </Flex>
        </Flex>
      )}
    </TopBarContainer>
  )
}

const TopBarContainer = styled('div', {
  height: '56px',
  boxSizing: 'border-box',
  padding: '$2 $4',
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'relative',
  zIndex: 2,
  boxShadow: '$bottom',
  backgroundColor: '$gs2',
  '@bp2': {
    padding: '$2 $7',
  },
  variants: {
    unsavedEditsView: {
      true: {
        backgroundColor: '$gs11',
        transition: 'background-color 0.3s ease-in-out',
      },
      false: {
        backgroundColor: '$gs1',
        transition: 'background-color 0.3s ease-in-out',
      },
    },
  },
  defaultVariants: {
    unsavedEditsView: false,
  },
})

const ItemContainer = styled('span', {
  backgroundColor: '$gs2',
  display: 'flex',
  flexFlow: 'row nowrap',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '$2 $2',
  borderRadius: '$3',
  border: 'none',
  cursor: 'pointer',
  transition: 'background-color $normal ease-in-out',
  zIndex: 2,
  '&:hover': {
    backgroundColor: '$gs6',
    transition: 'background-color $normal ease-in-out',
  },
})
